import React, { useEffect, useState } from 'react';
import './travelanimation.css';

const TravelAnimation = () => {


  return (
    <div className={`travel_animation show-animation`}>
      <ul>
        {[...Array(7)].map((_, index) => (
          <li key={index}></li>
        ))}
      </ul>
    </div>
  );
};

export default TravelAnimation;
