import React, { useState, useEffect } from 'react';
import './title.css';

const letters = "abcdefghijklmnopqrstuvwxyz";

const Title = () => {
  const [intervalId, setIntervalId] = useState(null);

  const handleMouseOver = (event) => {
    let iteration = 0;

    clearInterval(intervalId);

    const newIntervalId = setInterval(() => {
      event.target.innerText = event.target.innerText
        .split("")
        .map((letter, index) => {
          if (index < iteration) {
            return event.target.dataset.value[index];
          }

          return letters[Math.floor(Math.random() * 26)];
        })
        .join("");

      if (iteration >= event.target.dataset.value.length) {
        clearInterval(newIntervalId);
      }

      iteration += 1 / 3;
    }, 30);

    setIntervalId(newIntervalId);
  };

  return (
    <div className="title-container">
      <h1 onMouseOver={handleMouseOver} data-value="Seyitan Adeleke">
        Seyitan Adeleke
      </h1>
    </div>
  );
};

export default Title;
