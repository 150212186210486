import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { BiEqualizer } from 'react-icons/bi';
import { Outlet, useLocation } from 'react-router-dom'


const Layout = ({handleMusicClick, isMusicPlaying}) => {
  const location = useLocation();

  return (
    <div>
      {
        (
          location.pathname.includes('/loading...') ||
          location.pathname.includes('/content')
        ) && (
          <div className="background-audio" onClick={handleMusicClick}>
            {isMusicPlaying ? <h6>SOUND ON</h6> :<h6>SOUND OFF</h6>}
            {isMusicPlaying ? <BiEqualizer className='background-audio-icon' /> : <AiOutlineClose className='background-audio-icon' />}
          </div>
      )}
      
        <Outlet />
    </div>
  )
}

export default Layout