import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import logo from './logo.svg';
import './App.css';
import Layout from './Layout';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import ContentPage from './pages/ContentPage/ContentPage';
import Cursor from './components/Cursor/Cursor'
import Music from './components/Music/Music';
import FourZeroFourPage from './pages/404Page/FourZeroFourPage';
import BlogListPage from './pages/BlogListPage/BlogListPage';
import BlogPage from './pages/BlogPage/BlogPage';
import Rejection1 from './pages/BlogPage/Chapters/Rejection1';
import Rejection2 from './pages/BlogPage/Chapters/Rejection2';
import Rejection3 from './pages/BlogPage/Chapters/Rejection3';




function App() {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const toggleMusic = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  const turnOnMusic = () => {
    setIsMusicPlaying(true);
  };


  return (
    <>
      <Cursor />
      {isMusicPlaying && <Music />}
      <div className='grainy'></div>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout handleMusicClick={toggleMusic} isMusicPlaying={isMusicPlaying}/>} >
            <Route path={`/`}  index element={<LandingPage onMusicClick={toggleMusic}  />} />
            <Route path="/loading..." element={<LoadingPage />} />
            <Route path="/content" element={<ContentPage />} />
            <Route path="/content/:project?" element={<ContentPage />} />
            <Route path="/blogs" element={<BlogListPage />} />
            <Route path="/blogs/:index?" element={<BlogPage />} />
            <Route path="/404" element={<FourZeroFourPage />} />
            

            <Route path="/blogs/rej1" element={<Rejection1 />} />
            <Route path="/blogs/rej2" element={<Rejection2 />} />
            <Route path="/blogs/rej3" element={<Rejection3 />} />

          </Route>

        </Routes>
      </BrowserRouter>

      

    </>
    
    
  );
}

export default App;

