import React from 'react'
import { Link } from 'react-router-dom';
import '../blogpage.css'
import YoutubeEmbed from '../../../assets/YoutubeEmbed';

const Rejection1 = () => {
    return (
        <div className='blog-page'>
            <div className="blog-page_header">
                <div className="blog-page_header_title">
                    <h2>asking a stranger to autograph my sneakers</h2>
                    <p>01-08-24</p>

                </div>
                {/* <div className="blog-page_header_other">
                    <span><MdHeadphones /></span>
                </div> */}
                
            </div>
            <div className="blog-page_content">
                I didn't think coming up with prompts would be this hard. I've decided to write a list of them out to motivate me to get started and just thinking them up is making my heart pound and my palms sweat. I have a stomach ache and my mind is actively rejecting everything I come up with. Everything my mind seems reject, I write it down. I really don't know if I can do this, but the fact that I'm this nervous means I have to.
                
                <br />
                <br />
                <YoutubeEmbed embedId='FHEnww_Rk0k' />
                <br />
                <br />
                I walked around for about 20 minutes constantly rethinking doing this; I still vividly remember the moment where I spotted the dude (I really wish I asked for his name!) and not believing that I was about to walk up to him and ask to autograph my sneakers. He was extremely nice about it and did it without even questioning me. Still not very excited for tommorrow... 
                <br />
                <br />
                <img src='https://www.sheyitawn.com/sneakers.png' alt='signed sneakers' />
                
            </div>
            <div className="blog-page_footer">
                from sheyitawn
            </div>
        </div>
    )
}

export default Rejection1