import React from 'react'
import { Link } from 'react-router-dom';
import '../blogpage.css'
import YoutubeEmbed from '../../../assets/YoutubeEmbed';

const Rejection3 = () => {
    return (
        <div className='blog-page'>
            <div className="blog-page_header">
                <div className="blog-page_header_title">
                    <h2>buying something with a wooden doll</h2>
                    <p>03-08-24</p>

                </div>
                {/* <div className="blog-page_header_other">
                    <span><MdHeadphones /></span>
                </div> */}
                
            </div>
            <div className="blog-page_content">
                Today, I asked a stranger to let me buy something with my little wooden doll. I walked up to a chicken shop and asked him to give me whatever he wanted for the doll. He looked at me, smiled and filled a box with food. 
                <br />
                <br />
                I truly could not believe this was happening, as I chose this prompt specifically with the intention to get a 'no'. From this reaction (and the rest) I have decided to go with even more extreme requests, to hopefully get people thinking about it for longer.
                <br />
                <br />
                I was determined to get a 'no' today so right after this store, I went to the store right next to it and asked if I could buy the mayo for the doll. I did in fact get the no.
                <br />
                <br />
                <YoutubeEmbed embedId='D1KnZy3kZVo' />
                
                <img src='h\ttps://www.sheyitawn.com/woodendoll.png' alt='wooden doll' />
                
            </div>
            <div className="blog-page_footer">
                from sheyitawn
            </div>
        </div>
    )
}

export default Rejection3