import React from 'react'
import { Link } from 'react-router-dom'

const FourZeroFourPage = () => {
  return (
        <>
            <div><h1>404</h1></div>
            <div><h4>How did you get here?</h4></div>
            <div><Link to={'/'}><h5>Click here to back home</h5></Link></div>

        </>
  )
}

export default FourZeroFourPage