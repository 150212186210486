import React, { useRef, useEffect } from 'react';
const Music = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    playMusic();
  }, []);

  const playMusic = () => {
    audioRef.current.play();
  };

  return (
    <audio ref={audioRef} src="https://sheyitawn.com/background.wav" loop />
  );
};

export default Music;
