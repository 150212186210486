import React, { useEffect, useState } from 'react'

const Symbols = () => {
  const [year, setYear] = useState('&%^.?')

  useEffect(() => {
    const symbols = [
      '&%^.?', 
      '%$£"*', 
      '"{@:>.', 
      '~¬|<?/', 
      '?,./]#']; // List of symbols
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % symbols.length;
      setYear(symbols[currentIndex]);
    }, 100); // Change symbol every 1000 milliseconds (1 second)

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);
  return (
    <h3>[{year}]</h3>
  )
}

export default Symbols