import React from 'react'
import './button.css'
const Button = ({name, onClick}) => {
  return (
    
      
      <button onClick={onClick}>
        <div className='button'>
          <h4>{name}</h4>
        </div>
      </button>
    
  )
}

export default Button