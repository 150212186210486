import React, { useState } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';

const Dial = ({ currentYear, onYearChange}) => {
  const [year, setYear] = useState(null);


  return (
    <div className='dial'>

      <CircularSlider
        label="YEAR"
        width={180}
        labelColor="var(--clr-grey)"
        knobColor="var(--clr-light)"
        progressColorFrom="var(--clr-light)"
        progressColorTo="var(--clr-light)"
        progressSize={5}
        trackColor="var(--clr-grey)"
        trackSize={5}
        dataIndex={0}
        data={[null, 2017, 2019, 2020, 2021, 2022, 2023]}
        knobSize={20}
        knobPosition={'top'}
        labelFontSize={'.7em'}
        valueFontSize={'3.6em'}
        verticalOffset={'1rem'}
        progressLineCap="flat"
        onChange={(value) => {
          onYearChange(value);
        }}
      />
    </div>
  );
};

export default Dial;
