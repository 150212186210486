import React, { useEffect } from 'react';
import gsap from 'gsap';  // Import GSAP
import './cursor.css';

const Cursor = () => {
  useEffect(() => {
    const GlowCursor = class {
      constructor() {
        this.initCursor();
      }

      initCursor() {
        this.outerCursor = document.querySelector('.circle-cursor--outer');
        this.innerCursor = document.querySelector('.circle-cursor--inner');
        this.outerCursorBox = this.outerCursor.getBoundingClientRect();
        this.outerCursorSpeed = 0;
        this.clientX = -100;
        this.clientY = -100;
        this.showCursor = false;

        const unveilCursor = () => {
          gsap.set(this.innerCursor, {
            x: this.clientX,
            y: this.clientY,
          });

          gsap.set(this.outerCursor, {
            x: this.clientX - this.outerCursorBox.width / 2,
            y: this.clientY - this.outerCursorBox.height / 2,
          });

          setTimeout(() => {
            this.outerCursorSpeed = 1;
          }, 100);

          this.showCursor = true;
        };

        document.addEventListener('mousemove', unveilCursor);

        document.addEventListener('mousemove', (e) => {
          this.clientX = e.clientX;
          this.clientY = e.clientY;
        });

        const render = () => {
          gsap.set(this.innerCursor, {
            x: this.clientX,
            y: this.clientY,
          });

          if (!this.isStuck) {
            gsap.to(this.outerCursor, this.outerCursorSpeed, {
              x: this.clientX - this.outerCursorBox.width / 2,
              y: this.clientY - this.outerCursorBox.height / 2,
            });
          }
          if (this.showCursor) {
            document.removeEventListener('mousemove', unveilCursor);
          }
          requestAnimationFrame(render);
        };
        requestAnimationFrame(render);
      }
    };

    const cursorAnim = new GlowCursor();
    // Cleanup on component unmount
    const unveilCursor = () => {}; // Define empty unveilCursor for cleanup
    return () => {
      document.removeEventListener('mousemove', unveilCursor);
    };
  }, []);

  return (
    <div>
      <div className="circle-cursor circle-cursor--inner"></div>
      <div className="circle-cursor circle-cursor--outer"></div>
    </div>
  );
};

export default Cursor;
