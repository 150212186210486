import React from 'react'
import { Link } from 'react-router-dom';
import './social.css'
import {AiFillBook, AiFillGithub, AiFillHome, AiFillMail, AiFillMessage} from 'react-icons/ai'
import Button from '../Button/Button';

const Social = ({blogs}) => {
  const downloadResume = () => {
    fetch('https://www.sheyitawn.com/Seyitan%20Adeleke%27s%20Resume.pdf').then(response => {
        response.blob().then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = "Seyitan Adeleke's Resume";
          alink.click();
        })
    })
}
  return (
    <div className='social'>
      
      <div className="social-icons">
        <Link to={'/'}><div className="social-icon"><AiFillHome /></div></Link>
        <a href="https://github.com/sheyitawn"><div className="social-icon"><AiFillGithub /></div></a>
        <a href="mailto:sheyitawn@gmail.com"><div className="social-icon"><AiFillMail/></div></a>
          
        <div className="social-icon" onClick={downloadResume}>
          <AiFillBook />
          
        </div>
      </div>
      <div className="social-blog">
      {/* <Button name={'TRAVEL'} onClick={() => {}}/> */}

    {
      blogs ? 
      <Link style={{ textDecoration: 'none' }} to={'/blogs'}>
        <div className='social-blog_button'>
          <h4>LOGS</h4>
        </div>
      </Link>:
      <></>
    }
      


      </div>
    </div>
  )
}

export default Social