import React, { useEffect } from 'react'
import './bloglistpage.css'
import { MdArrowOutward } from "react-icons/md";
import { Link } from 'react-router-dom';
import { blogs } from '../../assets/bloglist';



const BlogListPage = () => {

    
    

    return (
        <div className='blog-list'>
            <h2>LOGS</h2>
            {blogs.map((blog, index) => (
                <Link style={{ textDecoration: 'none' }} to={`/blogs/${index}`}>
                    <div className='blog-list_blog' key={blog.title}>
                        <img src={blog.img} alt={blog.title} />

                        <div className="blog-list_blog_content">
                            <div className='blog-list_blog_content_arrow'><MdArrowOutward /></div>
                            <h2>{blog.title}</h2>
                            <p className='truncate'>{blog.content}</p>
                            <p>{blog.date}</p>
                        </div>
                        
                    </div>
                </Link>
            ))}
           
        </div>
    )
}

export default BlogListPage