// import { readFileSync } from 'fs';
// import { join } from 'path';

 // // Helper function to read file content
// const readFileContent = (fileName) => {
//     return fs.readFileSync(path.join(__dirname, fileName), 'utf-8');
// };

export const blogs = [
    {
        title: "Overcoming Rejection",
        date: "31-07-2024",
        content: "I've been reading a lot more recently, ever since I changed my mindset towards books. I've realised (through Naval's method) that if I stopped treating book as big achievements, and rather as just an activity I enjoy doing, I would read more books, and most importantly, books I enjoy. I now see books the way I see YouTube videos; if I'm not interested, I move on. No more forcing myself to read something I'm just not into.\nRecently, I stumbled across 'Rejection Proof' by Jia Jiang. It made me realise a problem I didn't even know I had. I think everyone is generally aware that they have a regular amount of rejection fear, whether from meeting new people or trying things out of the ordinary. But things like changing a university degree or expressing controversial beliefs or leaving a job or even going against your family's plans for you (all things I've experienced) are a form of avoiding rejection.\nIt's something to do with our biology where being part of a pack was essential for survival. Being ostracized would mean fending for yourself, which was often impossible. This notion wired into our DNA is rather useless now (for the most part), and in turn does more harm than good.\nIn the book, Jia tries a challenge to get rejected 100 times, to build his resilience to rejection. I thought this was a great idea to try, not only because it would supercharge the progress towards my goals, but it sounded really fun. Watching through his videos was quite encouraging (although sometimes extremely uncomfortable, making me rethink this), especially when he got a yes and his outrageous request fulfilled. \nI'm going to record my 30 days and share the results here along with a note about each encounter. Just writing this is making me nervous - I haven't even thought about the first day. I'll try to keep everything as original as possible; there's a million things I can get rejected for.",
        refs: [
            {
                name: 'The Almanac of Naval Ravikant',
                link: 'https://www.google.com/search?q=the+almanac+of+naval+ravikant'
            },
            {
                name: 'Rejection Proof',
                link: 'https://www.rejectiontherapy.com/book'
            },
            {
                name: 'Day 1: Asking a stranger to autograph my sneakers',
                link: '/blogs/rej1'
            },
            {
                name: 'Day 2: Asking someone to take a selfie with their dog',
                link: '/blogs/rej2'
            },
            {
                name: 'Day 3: Buying something with a wooden doll',
                link: '/blogs/rej3'
            }
        ],
        img: "https://www.sheyitawn.com/overcoming_rejection.png"
    },
    {
        title: "Setup",
        date: "03-08-2024",
        content: "Laptop: Asus something very good\n ",
        refs: [
            // {
            //     name: 'The Almanac of Naval Ravikant',
            //     link: 'https://www.google.com/search?q=the+almanac+of+naval+ravikant'
            // },

        ],
        img: "https://www.sheyitawn.com/overcoming_rejection.png"
    },

];

{/* <Link to={'/'}></Link> */}