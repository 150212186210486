import React, { useState, useEffect } from 'react';
import './loadingpage.css';
import { useNavigate } from 'react-router-dom';


const LoadingPage = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [statementIndex, setStatementIndex] = useState(0);
  const statements = [
    'Fastening seat belts...',
    'Revving engines...', 
    'Programming the flux capacitors...', 
    'Calibrating the nostalgia meter....',
    'Setting the coordinates...',
    'Syncing the beats...',
    'Checking for paradoxical paradoxes...',
    'Finishing up lunch... '
  ];
  
  useEffect(() => {
    const progressInterval = setInterval(() => {
      if (progress < 100) {
        setProgress(prevProgress => Math.min(prevProgress + 10, 100));
      } else {
        clearInterval(progressInterval);
        setTimeout(() => {
          navigate("/content");
        }, 1000);
      }
    }, 1000);

    const statementInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * statements.length);
      setStatementIndex(randomIndex);
    }, 1000); // Changed interval to 3000ms to match progress bar update

    return () => {
      clearInterval(progressInterval);
      clearInterval(statementInterval);
    };
  }, [progress, statements.length]);

  const progressBarStyle = {
    width: `${progress}%`,
    transition: 'width 1s linear' // Smooth transition over 1 second
  };

  return (
    <div className="loading_page">
      <h3>ENTERING CAPSULE</h3>
      <div className="loading_page-progress_bar">
        <div style={progressBarStyle}></div>
      </div>
      <div className="loading_page-statements">
        <p>{statements[statementIndex]}</p>
      </div>
    </div>
  );
};

export default LoadingPage;
