import React from 'react'
import { Link } from 'react-router-dom';
import '../blogpage.css'
import YoutubeEmbed from '../../../assets/YoutubeEmbed';

const Rejection2 = () => {
    return (
        <div className='blog-page'>
            <div className="blog-page_header">
                <div className="blog-page_header_title">
                    <h2>asking a stranger to let me take a selfie with their dog</h2>
                    <p>02-08-24</p>

                </div>
                {/* <div className="blog-page_header_other">
                    <span><MdHeadphones /></span>
                </div> */}
                
            </div>
            <div className="blog-page_content">
                Today, I asked a stranger to let me take a selfie with their dog. I remember the same distinct feeling where right before I did it, My whole body was rejecting the fact that I was walking up to a stranger to ask such a weird request.
                <br />
                <br />
                <YoutubeEmbed embedId='Jz1P5XV11UY' />
                <br />
                <br />
                I got to speak to Natalie (or Julie, I don't remember :( I actually asked this time but the nervousness made me forget almost instantly) and Fred (the dog!) and I think Fred was more excited than me to get that selfie. Day 3 here we come! - I should not be this excited
                <br />
                <br />
                <img src='https://www.sheyitawn.com/dogselfie.png' alt='selfie with Fred' />
                
            </div>
            <div className="blog-page_footer">
                from sheyitawn
            </div>
        </div>
    )
}

export default Rejection2