import React from 'react'
import { blogs } from '../../assets/bloglist';
import { Link, useParams } from 'react-router-dom';
import { MdHeadphones } from "react-icons/md";
import './blogpage.css'

const BlogPage = () => {

    const { index } = useParams();

    return (
        <div className='blog-page'>
            <div className="blog-page_header">
                <div className="blog-page_header_title">
                    <h2>{blogs[index].title}</h2>
                    <p>{blogs[index].date}</p>

                </div>
                <div className="blog-page_header_other">
                    <span><MdHeadphones /></span>
                </div>
                
            </div>
            <div className="blog-page_content">
                {
                    blogs[index].content.split('\n').map((paragraph, idx) => (
                    <p key={idx}>{paragraph}</p>))
                }
                <div className="blog-page_content-references">

                    {blogs[index].refs.map((ref) => (

                        <Link style={{ textDecoration: 'none' }} to={`${ref.link}`}>
                            <h4>
                                {ref.name}
                            </h4>
                        </Link>
                    ))}
                </div>
                
            </div>
            <div className="blog-page_footer">
                from sheyitawn
            </div>
        </div>
    )
}

export default BlogPage