import React, { useState, useEffect, useRef } from 'react';
import './contentpage.css';
import Dial from '../../components/Dial/Dial';
import Button from '../../components/Button/Button';
import Social from '../../components/Social/Social';
import Symbols from '../../components/Symbols/Symbols';
import TravelAnimation from '../../components/TravelAnimation/TravelAnimation';
import Project from './Project';
import { useNavigate } from 'react-router-dom';

const ContentPage = () => {
  const [currentYear, setCurrentYear] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const [showData, setShowData] = useState(false);
  const [dialTurned, setDialTurned] = useState(false);
  const [showCursor, setShowCursor] = useState(false);
  const navigate = useNavigate();
  const getXY = (e) => [e.clientX, e.clientY];

  // Add a ref for the trailing cursor
  const trailingCursorRef = useRef(null);

  const contentData = {
    2017: [["KFA", "Technology Solutions Developer"], ["Code_Comet", "Python Online Tutor"]],
    2019: [["Print_Haven", "Application Developer"], ["Portfolio_V1", "Application Developer"]],
    2020: [["Gravity_Based_Game", "Application Developer"], ["Chaotic_Twister", "Game Development"]],
    2021: [["Reeflex", "Software Developer"]],
    2022: [["Dental_Wealth_Builder", "Website Developer"], ["Portfolio_V2", "Web Developer"], ["Float_Therapy", "Web Developer"]],
    2023: [["VOOL", "Web Designer"], ["Square_Cleaners", "Web Developer"], ["Portfolio_V3", "This One"]]
  };

  const menuImageUrls = {
    KFA: 'https://www.sheyitawn.com/1.png', 
    Code_Comet: 'https://www.sheyitawn.com/7.png', 
    Print_Haven: 'https://www.sheyitawn.com/3.png',
    Portfolio_V1: 'https://www.sheyitawn.com/4.png',
    Gravity_Based_Game: 'https://www.sheyitawn.com/14.png',
    Chaotic_Twister: 'https://www.sheyitawn.com/5.png',
    Reeflex: 'https://www.sheyitawn.com/12.png',
    Dental_Wealth_Builder: 'https://www.sheyitawn.com/6.png',
    Portfolio_V2: 'https://www.sheyitawn.com/10.png',
    Square_Cleaners: 'https://www.sheyitawn.com/8.png',
    Float_Therapy: 'https://www.sheyitawn.com/13.png',
    Portfolio_V3: 'https://www.sheyitawn.com/9.png',
    VOOL: 'https://www.sheyitawn.com/11.png',
  };

  const handleYearChange = (year) => {
    setCurrentYear(year);
    setDialTurned(true);
    setShowContent(false);
  };

  const handleTravelButton = () => {
    if (dialTurned) {
      if (currentYear == null) {
        setDialTurned(false)
        alert('Please turn the dial first.');
      }
      setShowContent(true);
    } else {
      alert('Please turn the dial first.');
    }
  };

  const toggleData = (job) => {
    setShowContent(false);
    setShowData(true);
    navigate(`/content/${job}`);
  };

  useEffect(() => {
    const menuItems = document.querySelectorAll('.content_page-about-menu-hover-image .content_page-about-list_item');
    const cursor = document.querySelector('.content_page-about-menu-hover-image .content_page-about-cursor');
    
    const handleMouseEnter = (e) => {
      const [x, y] = getXY(e);
      const className = e.target.classList[1];
      const imageUrl = menuImageUrls[className];

      cursor.style.opacity = '1';
      cursor.style.backgroundImage = imageUrl ? `url(${imageUrl})` : 'none';
      cursor.style.transform = `translate(${x}px, ${y}px) scale(1)`;
      setShowCursor(true);
    };

    const handleMouseLeave = () => {
      cursor.style.opacity = '0';
      setShowCursor(false);
    };

    menuItems.forEach((menuItem) => {
      menuItem.addEventListener('mouseenter', handleMouseEnter);
      menuItem.addEventListener('mouseleave', handleMouseLeave);

      menuItem.addEventListener('mousemove', (e) => {
        if (showCursor) {
          const [x, y] = getXY(e);
          cursor.style.transform = `translate(${x}px, ${y}px) scale(1)`;
        }
      });
    });

    return () => {
      menuItems.forEach((menuItem) => {
        menuItem.removeEventListener('mouseenter', handleMouseEnter);
        menuItem.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [showCursor, getXY]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      try {
        if (!showCursor) return;
      if (showCursor) {
        const [x, y] = getXY(e);
        trailingCursorRef.current.style.transform = `translate(${x - 10}px, ${y - 10}px) scale(0.7)`;
      }
      } catch (error) {
        return
      }
      
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [showCursor, getXY]);

  return (
    <div className='content_page'>
      <div className='content_page-year'>
        {currentYear !== null ? <h3>{currentYear}</h3> : <Symbols />}
      </div>
      <div className='content_page-about'>
        <div className={showContent ? "content_page-about-container-w_content" : "content_page-about-container"}>
          {
            showContent ? 
            <div className='content_page-about-menu-hover-image'>
              <h4>
                {currentYear ? contentData[currentYear].map((data, index) => (
                  <div key={index} className={`content_page-about-list_item ${data[0]}`} onClick={() => toggleData(data[0])}>
                    <p>0{index + 1}</p>
                    <h2>{data[0]}</h2>
                    <h4>{data[1]}</h4>
                  </div>
                )) : ''}
              </h4>
              <div ref={trailingCursorRef} className="content_page-about-cursor trailing-cursor"></div>
            </div>
            : showData ? <Project />
            : <div className="content_page-animation"><TravelAnimation /></div>
          }
          {!showContent && <h5>TURN DIAL TO TRAVEL</h5>}
        </div>
      </div>
      <div className='content_page-controls'>
        <div className='content_page-controls-dial'>
          <Dial currentYear={currentYear} onYearChange={handleYearChange} />
        </div>
        <div className='content_page-controls-button'>
          <Button name={'TRAVEL'} onClick={handleTravelButton}/>
        </div>
        <div className='content_page-controls-details'>
          <h4>I'm a student studying BEng Robotics and creating on the side. Get in touch with me through the links below.</h4>
          <p><Social blogs={true}/></p>
          <h4>LAST SEEN: <span>Joining the Reeflex Waitlist @ www.reeflex.online</span></h4>
        </div>
      </div>
    </div>
  );
};

export default ContentPage;
