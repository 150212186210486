import React, { useRef, useState } from 'react';
import './landingpage.css';
import { Navigate } from 'react-router-dom';

import Social from '../../components/Social/Social';

import Title from '../../components/Title/Title';



const LandingPage = ({ onMusicClick }) => {
  const [circleSize, setCircleSize] = useState(0);
  const [navigate, setNavigate] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const intervalRef = useRef(null);


  
  if (navigate) {
    return <Navigate to={'/loading...'} />
  }

  const growCircle = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const maxRadius = Math.sqrt(screenWidth ** 2 + screenHeight ** 2) / 2; // Max radius to cover the screen

    let currentRadius = 0;
    const growthRate = 10;

    const interval = setInterval(() => {
      if (currentRadius < maxRadius) {
        currentRadius += growthRate;
        setCircleSize(currentRadius * 10); // Diameter
      } else {
        clearInterval(interval);
      }
    }, 10); // Adjust the interval as needed
  };


  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setMouseDown(false);
    clearInterval(intervalRef.current);
  };

  const handleMouseDown = () => {
    setMouseDown(true);
    console.log('holding');

    intervalRef.current = setInterval(() => {
    console.log('held');
    growCircle()
    setTimeout(() => {
      setNavigate(true);
      onMusicClick()
    }, 400);
    }, 1000);
  };

  const handleMouseUp = () => {
    setMouseDown(false);
    clearInterval(intervalRef.current);
    console.log('letting go');
  };

  




  return (
    <div className='landing_page'>
      <div className="circle" style={{ width: circleSize, height: circleSize }}></div>
      <div className="landing_page-header">
        <Title />
        <h3>SOFTWARE DEVELOPER</h3>
        <h4>TIME CAPSULE 2017-2023 • BRISTOL, UK</h4>
      </div>

      <div className="landing_page-content">
        <div className="landing_page-content-cta">
          <div
            className={`landing_page-content-cta-button ${hovered ? 'hovered' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <h5>CLICK & HOLD</h5>
            <h6>TO EXPLORE THE CAPSULE</h6>
            {hovered && <div className="hover-effect"></div>}
          </div>
        </div>
      </div>

      <div className="landing_page-footer">
        <Social blogs={false}/>

        
      </div>

    </div>
  );
}

export default LandingPage;


// on click, change classname to an animation where a circle grows from the center to fill the page 