import React from 'react'
import { useParams } from 'react-router-dom'
import './contentpage.css'
const Project = () => {

    const {project} = useParams()
    return (
        <div className='project_item'>
            {
                project === 'KFA'? 
                    <div>
                        Collaborated across teams, gathering feedback for improvements. Demonstrated strong problem-solving and teamwork skills. I produced promotional videos showcasing company offerings using Davinci Resolve, highlighting our premium rental business.
                    </div>
                : project === 'Code_Comet'? 
                    <div>
                        During lockdown, I organized 'Lockdown Coding Classes' as a self-taught Python tutor. Helping others learn to code and adapt during challenging times became a very fulfilling task, and I was able to foster a community of learners.
                    </div>
                : project === 'Print_Haven'? 
                    <div>
                        Developed an AR app using Kivy and OpenCV. Worked in a team, implementing image recognition and video integration features. Our collaborative efforts enhanced the app, offering an exciting augmented reality experience.
                    </div>
                : project === 'Portfolio_V1'? 
                    <div>
                        Embarked on my web development journey by creating my initial website using HTML and CSS. This project marked the beginning of my exploration into the world of website technologies.
                    </div>
                : project === 'Gravity_Based_Game'? 
                    <div>
                       Challenged in a game jam to create a gravity-manipulation game using pygame. I designed an engaging experience where players manipulate gravity to conquer levels.
                    </div>
                : project === 'Chaotic_Twister'? 
                    <div>
                        Innovated a unique twist on the game of Twister by integrating Arduino and C#. This electrifying version made gameplay exciting with a shock mechanism, enhancing traditional gameplay for a more immersive experience. It's game of twister that shocks you when you mess up. Comes with an app too.
                    </div>
                : project === 'Reeflex'? 
                    <div>
                        I worked in a tech startup, working on a MERN stack web application. Collaborated to design user-friendly interfaces, integrate APIs, and optimize performance, contributing significantly to the startup's growth and mission.
                    </div>
                : project === 'Dental_Wealth_Builder'? 
                    <div>
                        Collaborated with dentists to create a platform selling dental courses. Customized web development and design to suit their needs, offering a seamless online space to market and distribute valuable courses.
                    </div>
                : project === 'Portfolio_V2'? 
                    <div>
                        Redesigned my personal portfolio, showcasing growth and progress using HTML, CSS, JavaScript, and jQuery. The journey illustrated my evolving skills and experiences, presenting a more refined and dynamic online portfolio.
                    </div>
                : project === 'VOOL'? 
                    <div>
                        Collaborated on an innovative streetwear ecommerce website, focusing on frontend and backend development. Utilized React, TypeScript, and Tailwind CSS to create an aesthetically appealing UI, aligning with project objectives and goals.
                    </div>
                : project === 'Square_Cleaners'? 
                    <div>
                        Transformed a cleaning company's online presence. Leveraged web development skills to design an engaging website, effectively communicating the company's services and brand identity.
                    </div>
                : project === 'Float_Therapy'? 
                    <div>
                        Partnered with a floatation therapist, crafting a website that showcased their services. Through web development, we highlighted the unique experience they offered, attracting more clients to their practice.
                    </div>
                : project === 'Portfolio_V3'? 
                    <div>
                        Created the website you're on right now. It was created to encapsulate my progression in web development. Utilizing React, HTML, and CSS, I documented my journey through my portfolio's, providing a glimpse into my development and experiences over time.
                    </div>

                : <></>
            }
        </div>
    )
}

export default Project